import { atom } from "recoil";
import { IConversations, IMessages } from "./type";

interface ConversationList {
  page: number;
  itemPerPage: number;
  totalPage: number;
  loading: boolean;
  data: IConversations[];
}

interface MessageList {
  page: number;
  itemPerPage: number;
  totalPage: number;
  loading: boolean;
  new_message: string;
  data: IMessages[];
}

const convertsationList = atom<ConversationList>({
  key: "conversation_list",
  default: {
    page: 1,
    itemPerPage: 10,
    totalPage: 0,
    loading: false,
    data: [],
  },
});

const conversationDetails = atom<IConversations | undefined>({
  key: "conversation_details",
  default: undefined,
});

const messageList = atom<MessageList>({
  key: "message_list",
  default: {
    page: 1,
    itemPerPage: 20,
    totalPage: 0,
    loading: false,
    data: [],
    new_message: "",
  },
});

const ChatAtom = {
  convertsationList,
  messageList,
  conversationDetails,
};

export default ChatAtom;
