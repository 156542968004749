import React, { useEffect } from "react";
import "theme/index.less";
import Routers from "routers";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { OnBoardingApi, OnBoardingAtom } from "services/onboarding";
import RecoilNexus from "recoil-nexus";
import PopupKeyword from "components/PopupKeyword/PopupKeyword";

const App: React.FC = () => {
  // const [showPopup, setShowPopup] = useState(true);
  // const user = useRecoilValue(UserAtom.userInfo);
  const setPayments = useSetRecoilState(OnBoardingAtom.paymentMethods);

  useEffect(() => {
    OnBoardingApi.getPaymentMethods().then(
      (res) =>
        res &&
        setPayments(
          res
            .filter((item) => item.show_for.includes("agent"))
            .map((item, i) => {
              return { ...item, id: i + 1 };
            })
        )
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <RecoilRoot>
        <RecoilNexus />
        <Routers />
        {/* {user.id === localStorage.getItem("new_login") && (
          <ModalContainer
            open={showPopup}
            onClose={() => {
              setShowPopup(false);
              localStorage.removeItem("new_login");
            }}
          >
            <Popup />
          </ModalContainer>
        )} */}

        <PopupKeyword />
      </RecoilRoot>
    </div>
  );
};

export default App;
