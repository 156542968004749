import classNames from "classnames";
import DataNonePage from "components/DataNonePage";
import Icon from "components/Icon";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { NotificationAtom } from "services/notification";
import "./Notification.less";
import NotifyItem from "./NotifyItem";

interface NotificationProps {
  sticky?: boolean;
}

const NotifyNotification: React.FC<NotificationProps> = ({
  sticky = false,
}) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const notificationList = useRecoilValue(NotificationAtom.notificationList);
  const notificationSystemList = useRecoilValue(
    NotificationAtom.notificationSystemList
  );

  const handleActiveTab = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    tab: number
  ) => {
    e.stopPropagation();
    setTab(tab);
  };

  const renderNotificationList = () => {
    const notifications = tab
      ? [...notificationSystemList.data]
      : [...notificationList.data];

    if (notifications.length) {
      return notifications
        .slice(0, 10)
        .map((notification, index) => (
          <NotifyItem key={index} notify={notification} />
        ));
    }

    return <DataNonePage />;
  };

  return (
    <div className="notification">
      <div className={classNames("title", { sticky: sticky ? "sticky" : "" })}>
        <div className="title_list">
          <span
            className={tab === 0 ? "active" : ""}
            onClick={(event) => handleActiveTab(event, 0)}
          >
            Thông báo
          </span>
          <span className="line"></span>
          <span
            className={tab === 1 ? "active" : ""}
            onClick={(event) => handleActiveTab(event, 1)}
          >
            Hệ thống
          </span>
        </div>
        <span
          className="see_more"
          onClick={() => navigate(tab ? "/system" : "/notify")}
        >
          Xem tất cả
          <Icon icon="arrow-right" size={12} />
        </span>
      </div>
      <div className="list">{renderNotificationList()}</div>
    </div>
  );
};

export default NotifyNotification;
