import classNames from "classnames";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";

import Icon from "components/Icon";
import { IConversations } from "services/chat/type";
import Avatar from "components/Avatar";

import "./MessageItem.less";

interface MessageItemProps {
  message: IConversations;
  isActive: boolean;
}

const MessageItem: React.FC<MessageItemProps> = ({ message, isActive }) => {
  const nav = useNavigate();
  return (
    <div
      className={classNames("message-item", {
        active: isActive,
      })}
      onClick={() => nav("/message?id=" + message.id)}
    >
      <Avatar
        src={message?.customer?.avatar}
        alt={message?.customer?.full_name}
      />
      <div className="user">
        <p>{message?.customer?.full_name || "-"}</p>
        <p>
          {message?.last_message?.message?.type === "IMAGE"
            ? "Bạn đã gửi 1 ảnh"
            : message?.last_message?.message?.content}
        </p>
        <div className="time">
          <Icon icon="clock" size={13} color="#7F859F" />

          {message?.last_message?.created_at ? (
            <span>
              <ReactTimeAgo
                date={new Date(message.last_message.created_at)}
                className="time"
              />
            </span>
          ) : (
            "-"
          )}
        </div>
      </div>
      {/* <Icon icon="more" className="more" /> */}
    </div>
  );
};

export default MessageItem;
