export const listUserPopup = [
  {
    link: "/agency/beneficiary-bank",
    text: "Thông tin ngân hàng thụ hưởng",
  },
  {
    link: "/agency/register",
    text: "Thông tin đăng ký kinh doanh",
  },
  {
    link: "/agency/change-password",
    text: "Đổi mật khẩu",
  },
  // {
  //   link: "/agency/bill-confirm",
  //   text: "Hóa đơn phí sàn",
  // },
];

// Will be reenabled after integrated API
export const listSidebar = [
  { icon: "shield", text: "Quản lý SP bảo hiểm", link: "/insurance" },
  { icon: "product", text: "Quản lý sản phẩm", link: "/product" },
  { icon: "service", text: "Quản lý dịch vụ", link: "/service" },
  { icon: "service", text: "Quản lý dịch vụ cứu hộ", link: "/service-rescue" },
  {
    icon: "cart",
    text: "Quản lý mua hàng",
    link: "/purchase",
  },
  {
    icon: "calendar",
    text: "Quản lý hoạt động dịch vụ",
    link: "/booking",
  },
  { icon: "sale", text: "Quản lý giảm giá", link: "/promotion" },
  { icon: "people", text: "Quản lý khách hàng", link: "/customer" },
  {
    icon: "agency",
    text: "Thông tin đơn vị",
    link: "/agency",
  },
  {
    icon: "sales",
    text: "Doanh thu",
    link: "/Revenue",
  },
  {
    icon: "message",
    text: "Tin nhắn",
    link: "/message",
  },
];

export const listSupportInfo = [
  {
    icon: "rescue",
    text: "Cứu hộ xe",
    link: "/rescue-onsite",
  },
  {
    icon: "flash-buy",
    text: "Flash Buy",
    link: "/flash-buy",
  },
  {
    icon: "help",
    text: "Trung tâm trợ giúp",
    link: "https://zalo.me/1646724311996750046",
  },
];
