import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { StorageConstant } from "services/storage";
import { UserAtom } from "services/user";

const ProtectedRoute: React.FC = ({ children }) => {
  const user = useRecoilValue(UserAtom.userInfo);
  const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);

  if (!user.is_verified || !token || !user.agent) {
    return <Navigate to="/sign-in" replace />;
  }

  // if (!user.company?.tax_id) {
  //   return <Navigate to="/business-info" replace />;
  // }

  // if (!user.agent?.id) {
  //   return <Navigate to="/agent-info" replace />;
  // }

  return <>{children}</>;
};

export default ProtectedRoute;
