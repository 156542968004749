import classNames from "classnames";
import Icon from "components/Icon";
import { listSidebar, listSupportInfo } from "data/SideBar";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { FlashBuyApi } from "services/flashbuy";
import { OnBoardingAtom } from "services/onboarding";
import { RescueApi } from "services/rescue";
import { SidebarAtom } from "services/sidebar";
import "./Sidebar.less";
import { v4 } from "uuid";
import { ServiceApi } from "services/service";
import { IService } from "services/service/type";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const agent = useRecoilValue(OnBoardingAtom.agentInfo);
  const [countFlashbuy, setCountFlashbuy] = useState(0);
  const [countRescue, setCountRescue] = useState(0);
  const isSidebar = useRecoilValue(SidebarAtom.isSidebar);
  const insuranceId = "65a347b9-3c84-4e23-9856-c7245d7bdffd";
  const [isRescueAgent, setIsRescueAgent] = useState(false);

  useEffect(() => {
    if (agent.id) {
      Promise.all([
        ServiceApi.getListServiceRescue(1, 1, agent.id, "Cứu hộ tại chỗ"),
        ServiceApi.getListServiceRescue(1, 1, agent.id, "Kéo xe cứu hộ")
      ]).then((RESPONSE_LIST) => {
        let rescueServiceList: IService[] = []
        RESPONSE_LIST.map((RESPONSE) => {
          const SERVICE = RESPONSE?.service_list[0]
          if (SERVICE?.name === "Kéo xe cứu hộ" || SERVICE?.name === "Cứu hộ tại chỗ") {
            rescueServiceList = [...rescueServiceList, SERVICE]
          }
        })
        console.log(rescueServiceList.length)
        if (rescueServiceList.length > 0) {
          setIsRescueAgent(true)

          Promise.all([
            RescueApi.getCountOnsite(agent.id || ""),
            RescueApi.getCountTowingRepair(agent.id || ""),
          ]).then((res: [number, number]) => {
            if (res[0] + res[1] - 1 < 0) {
              setCountRescue(res[0] + res[1])
              return
            }
            setCountRescue(res[0] + res[1] - 1)
          });
        }
      })

      FlashBuyApi.getCount(agent.id).then((res) => setCountFlashbuy(res));
    }
  }, [agent.id]);

  const renderSidebar = useCallback(() => {
    let isInsurance = false;
    if (insuranceId === agent.category_id) {
      isInsurance = true;
    }

    const handleDisableSidebar = (icon: string) => {
      if (
        !["shield", "product", "service", "calendar", "booking"].includes(icon)
      ) {
        return false;
      }

      if (
        (isInsurance && icon !== "shield") ||
        (!isInsurance && icon === "shield")
      ) {
        return true;
      }

      return false;
    };

    return (
      <div className="sidebar_list">
        {listSidebar.filter((sidebar) => !handleDisableSidebar(sidebar.icon)).map((sidebar, index) => (
          <div key={v4() + index}>
            <div
              className={classNames("item", {
                active:
                  sidebar.link === "/" ||
                    location.pathname
                      .split("")
                      .filter((locate) => locate.includes("/")).length < 2
                    ? sidebar.link === location.pathname
                    : location.pathname.includes(sidebar.link),
              })}

              onClick={() => navigate(sidebar.link)
              }
            >
              <Icon className="icon" icon={sidebar.icon} size={16} />
              <span>{sidebar.text}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }, [agent.category_id, location.pathname, navigate]);

  const renderSidebarBottom = useCallback(() => {
    let isInsurance = false;
    if (insuranceId === agent.category_id) {
      isInsurance = true;
    }

    return listSupportInfo.map((sidebarBottom, index) => (
      <div
        className={classNames("item", {
          active: location.pathname.includes(sidebarBottom.link),
          disabled:
            sidebarBottom.icon === "rescue" && !isRescueAgent
              ? true
              : false,
        })}
        key={index}
        onClick={() => {
          sidebarBottom.icon === "help"
            ? window.open(sidebarBottom.link, "_blank")
            : sidebarBottom.icon === "rescue" ? isRescueAgent && navigate(sidebarBottom.link) : !isInsurance && navigate(sidebarBottom.link);
        }}
      >
        <Icon className="icon" icon={sidebarBottom.icon} size={20} color="" />
        <span>{sidebarBottom.text}</span>

        {["Flash Buy", "Cứu hộ xe"].includes(sidebarBottom.text) && (
          <>
            {sidebarBottom.text === "Flash Buy" && countFlashbuy > 0 ? (
              <span className="count">
                {countFlashbuy}
              </span>
            ) : null}
            {sidebarBottom.text === "Cứu hộ xe" && countRescue > 0 ? (
              <span className="count">
                {countRescue}
              </span>
            ) : null}
          </>
        )}
      </div>
    ));
  }, [
    agent.category_id,
    location.pathname,
    countFlashbuy,
    countRescue,
    navigate,
    isRescueAgent
  ]);

  return (
    <section
      className={classNames("sidebar", {
        show: isSidebar,
      })}
    >
      {renderSidebar()}
      <div className="sidebar_line"></div>
      <div className="sidebar_bottom">{renderSidebarBottom()}</div>
    </section>
  );
};

export default Sidebar;
