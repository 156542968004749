import classNames from "classnames";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import React from "react";
import { useRecoilValue } from "recoil";
import { SidebarAtom } from "services/sidebar";
import "./Layout.less";

const Layout: React.FC = ({ children }) => {
  const isSidebar = useRecoilValue(SidebarAtom.isSidebar);

  return (
    <React.Fragment>
      <Header />
      <main
        className={classNames("layout", {
          isSidebar,
        })}
      >
        <Sidebar />
        <div className="layout_children">{children}</div>
      </main>
    </React.Fragment>
  );
};

export default Layout;
