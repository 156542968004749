import React from "react";
import "./Loading.less";
import ReactLoading from "react-loading";

const Loading: React.FC = () => {
  return (
    <div className="loading_overlay">
      <ReactLoading type="spin" color="white" height={100} width={100} />
    </div>
  );
};

export default Loading;
