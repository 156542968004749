import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import { FlashBuyAtom } from ".";
import {
  IAddFlashBuyRejectResponse,
  IAddFlashBuyResponse,
  IFlashBuyResponsePayload,
  IGetCountFlashbuyResponse,
  IGetDetailFlashBuyResponse,
  IGetListFlashBuyResponse,
} from "./type";

const getList = async (
  page: number,
  itemPerPage: number,
  agent_id?: string,
  product_name?: string
) => {
  setRecoil(FlashBuyAtom.list, {
    ...getRecoil(FlashBuyAtom.list),
    loading: true,
  });

  let params = {
    page,
    limit: itemPerPage,
    product_name,
    agent_id,
    is_done: false,
  };

  !product_name && delete params.product_name;

  const res = await CXApi<IGetListFlashBuyResponse>({
    url: "/v1/flash-buy-requests",
    method: "GET",
    params,
  });

  if (res.statusCode === 200) {
    setRecoil(FlashBuyAtom.list, {
      ...getRecoil(FlashBuyAtom.list),
      loading: false,
      total: res.data.total,
      data: res.data.flash_buy_request_list,
    });

    return res.data.flash_buy_request_list;
  }

  return undefined;
};

const getCount = async (agent_id: string) => {
  const res = await CXApi<IGetCountFlashbuyResponse>({
    url: "/v1/flash-buy-requests/agents/" + agent_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return 0;
};

const getDetails = async (id: string) => {
  setRecoil(FlashBuyAtom.details, {
    ...getRecoil(FlashBuyAtom.details),
    loading: true,
  });

  const res = await CXApi<IGetDetailFlashBuyResponse>({
    url: "/v1/flash-buy-requests/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(FlashBuyAtom.details, {
      ...getRecoil(FlashBuyAtom.details),
      loading: false,
      details: res.data,
    });
    return res.data;
  }

  return undefined;
};

const addResponse = async (
  agent_id: string,
  flash_buy_request_id: string,
  flash_buy_product_info: IFlashBuyResponsePayload
) => {
  const res = await CXApi<IAddFlashBuyResponse>({
    url: "/v1/flash-buy-responses",
    method: "POST",
    data: {
      agent_id,
      flash_buy_request_id,
      flash_buy_product_info,
    },
  });

  return res;
};

const addResponseReject = async (
  agent_id: string,
  flash_buy_request_id: string
) => {
  const res = await CXApi<IAddFlashBuyRejectResponse>({
    url: "/v1/flash-buy-responses/rejections",
    method: "POST",
    data: {
      agent_id,
      flash_buy_request_id,
    },
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return undefined;
};

const FlashBuyApi = {
  getList,
  getDetails,
  getCount,
  addResponse,
  addResponseReject,
};

export default FlashBuyApi;
