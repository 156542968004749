import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import ProductAtom from "./product.atom";
import {
  ICreateProductResponse,
  IDeleteManyProductResponse,
  IDeleteProductResponse,
  IGetDetailProductResponse,
  IGetListProductResponse,
  IProduct,
  IUpdateProductResponse,
  IUploadFileProductResponse,
} from "./type";
import OnboardingAtom from "services/onboarding/onboarding.atom";

const addProduct = async (data: IProduct) => {
  console.log(data)
  const res = await CXApi<ICreateProductResponse>({
    url: "/v1/products",
    method: "POST",
    data: data,
  });
  return res
};

const uploadFileProduct = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const res = await CXApi<IUploadFileProductResponse>({
    url: "/v1/products/bulk-upload",
    method: "POST",
    data: formData,
  });

  if (res.statusCode === 200) {
    return res.message;
  }

  return undefined;
};

const updateProduct = (id: string, data: IProduct) => {
  return CXApi<IUpdateProductResponse>({
    url: "/v1/products/" + id,
    method: "PUT",
    data: {
      name: data.name,
      price: data.price,
      discount_price: data.discount_price,
      guarantee_time: data.guarantee_time,
      guarantee_note: data.guarantee_note,
      description: data.description,
      images: data.images,
      is_guaranteed: data.is_guaranteed,
      // status: data.status,
      guarantee_time_unit: data.guarantee_time_unit,
      quantity: data.quantity,
    },
  });
};

const updateStatusProduct = (
  id: string,
  status: "ACTIVE" | "INACTIVE" | "OUT_OF_STOCK"
) => {
  return CXApi<IUpdateProductResponse>({
    url: "/v1/products/" + id,
    method: "PUT",
    data: {
      status,
    },
  });
};

const getListProduct = async (
  page: number,
  itemPerPage: number,
  agent_id?: string,
  name?: string,
  saveStore = true
) => {
  setRecoil(ProductAtom.list, {
    ...getRecoil(ProductAtom.list),
    loading: true,
  });
  let params = {
    page,
    limit: itemPerPage,
    agent_id,
    name,
    is_insurance_product: false,
    is_status_included: false,
  };

  !name && delete params.name;

  const res = await CXApi<IGetListProductResponse>({
    url: "/v1/products",
    method: "GET",
    params,
  });

  if (res.statusCode === 200) {
    saveStore &&
      setRecoil(ProductAtom.list, {
        ...getRecoil(ProductAtom.list),
        loading: false,
        total: res.data.total,
        data: res.data.product_list,
      });
    return res.data;
  }

  return undefined;
};

const getMostViewedProduct = async (
  agent_id?: string,
) => {
  const res = await CXApi<any>({
    url: "/v1/products/most-viewed/" + agent_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(ProductAtom.mostViewedProductList, res.data.product_list);
    return res.data.product_list;
  }

  return undefined;
};

const getDetailProduct = async (id: string) => {
  setRecoil(ProductAtom.details, {
    ...getRecoil(ProductAtom.details),
    loading: true,
  });

  const res = await CXApi<IGetDetailProductResponse>({
    url: "/v1/products/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    const currentAgentId = getRecoil(OnboardingAtom.agentInfo).id
    if (res.data.agent_id === currentAgentId) {
      setRecoil(ProductAtom.details, {
        details: res.data,
        loading: false,
      });
    } else {
      return undefined
    }
    return res.data;
  }

  return undefined;
};

const deleteProduct = (id: string) => {
  return CXApi<IDeleteProductResponse>({
    url: "/v1/products/" + id,
    method: "DELETE",
  });
};

const deleteManyProduct = (id: string[]) => {
  return CXApi<IDeleteManyProductResponse>({
    url: "/v1/products/delete",
    method: "DELETE",
    data: id,
  });
};

const ProductApi = {
  updateStatusProduct,
  addProduct,
  updateProduct,
  getListProduct,
  getDetailProduct,
  deleteProduct,
  deleteManyProduct,
  uploadFileProduct,
  getMostViewedProduct
};

export default ProductApi;
