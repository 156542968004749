import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import NotificationAtom from "./notification.atom";
import {
  IGetCountNotificationResponse,
  IGetListNotificationsResponse,
  IUpdateNotificationsResponse,
} from "./type";

const getNotificationList = async (
  page: number,
  itemPerPage: number,
  user_id: string
) => {
  setRecoil(NotificationAtom.notificationList, {
    ...getRecoil(NotificationAtom.notificationList),
    loading: page === 1 ? false : true,
  });
  const res = await CXApi<IGetListNotificationsResponse>({
    url: "/v1/notifications",
    method: "GET",
    params: {
      page,
      limit: itemPerPage,
      user_id,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(NotificationAtom.notificationList, {
      ...getRecoil(NotificationAtom.notificationList),
      page,
      itemPerPage,
      totalPage: Math.ceil(res.data.total / itemPerPage),
      loading: false,
      data:
        page === 1
          ? res.data.notification_list
          : getRecoil(NotificationAtom.notificationList).data.concat(
              res.data.notification_list
            ),
    });

    return res.data.notification_list;
  }

  return undefined;
};

const getNotificationSystemList = async (page: number, itemPerPage: number) => {
  setRecoil(NotificationAtom.notificationSystemList, {
    ...getRecoil(NotificationAtom.notificationSystemList),
    loading: page === 1 ? false : true,
  });
  const res = await CXApi<IGetListNotificationsResponse>({
    url: "/v1/notifications/app",
    method: "GET",
    params: {
      page,
      limit: itemPerPage,
      target_group: "AGENT",
    },
  });

  if (res.statusCode === 200) {
    setRecoil(NotificationAtom.notificationSystemList, {
      ...getRecoil(NotificationAtom.notificationSystemList),
      page,
      itemPerPage,
      totalPage: Math.ceil(res.data.total / itemPerPage),
      loading: false,
      data:
        page === 1
          ? res.data.notification_list
          : getRecoil(NotificationAtom.notificationSystemList).data.concat(
              res.data.notification_list
            ),
    });

    return res.data.notification_list;
  }

  return undefined;
};

const getCountNotification = async (user_id: string) => {
  const res = await CXApi<IGetCountNotificationResponse>({
    url: "/v1/notifications/users/" + user_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(NotificationAtom.notificationList, {
      ...getRecoil(NotificationAtom.notificationList),
      count: res.data,
    });
    return res.data;
  }

  return 0;
};

const updateNotificaton = async (id_notification: string) => {
  const res = await CXApi<IUpdateNotificationsResponse>({
    url: "/v1/notifications/" + id_notification,
    method: "PUT",
    data: {
      is_read: true,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(NotificationAtom.notificationList, {
      ...getRecoil(NotificationAtom.notificationList),
      data: getRecoil(NotificationAtom.notificationList).data.map(
        (notification) => {
          if (notification.id === id_notification) {
            return { ...notification, is_read: true };
          }
          return notification;
        }
      ),
    });

    return res.data;
  }

  return undefined;
};

const NotificationApi = {
  getNotificationList,
  getNotificationSystemList,
  getCountNotification,
  updateNotificaton,
};

export default NotificationApi;
