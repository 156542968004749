export enum NotificationTypeEnum {
  CARX = "CARX",
  AGENT_CONFIRM_ORDER = "AGENT_CONFIRM_ORDER",
  AGENT_CANCEL_ORDER = "AGENT_CANCEL_ORDER",
  AGENT_CONFIRM_BOOKING = "AGENT_CONFIRM_BOOKING",
  AGENT_CANCEL_BOOKING = "AGENT_CANCEL_BOOKING",
  AGENT_RESPONSE_FLASH_BUY_REQUEST = "AGENT_RESPONSE_FLASH_BUY_REQUEST",
  AGENT_RESPONSE_RESCUE_REQUEST = "AGENT_RESPONSE_RESCUE_REQUEST",
  AGENT_SEND_QUOTATION = "AGENT_SEND_QUOTATION",
  ORDER_DELIVERING = "ORDER_DELIVERING",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  BOOKING_COMPLETED = "BOOKING_COMPLETED",
  BOOKING_WAITING_FOR_PAYMENT = "BOOKING_WAITING_FOR_PAYMENT",
  CUSTOMER_CREATE_ORDER = "CUSTOMER_CREATE_ORDER",
  CUSTOMER_CANCEL_ORDER = "CUSTOMER_CANCEL_ORDER",
  CUSTOMER_CREATE_BOOKING = "CUSTOMER_CREATE_BOOKING",
  CUSTOMER_CANCEL_BOOKING = "CUSTOMER_CANCEL_BOOKING",
  CUSTOMER_CREATE_FLASH_BUY_REQUEST = "CUSTOMER_CREATE_FLASH_BUY_REQUEST",
  CUSTOMER_CREATE_RESCUE_REQUEST = "CUSTOMER_CREATE_RESCUE_REQUEST",
  CUSTOMER_ACCEPT_FLASH_BUY_RESPONSE = "CUSTOMER_ACCEPT_FLASH_BUY_RESPONSE",
  CUSTOMER_REPORT_ORDER = "CUSTOMER_REPORT_ORDER",
  CUSTOMER_REPORT_BOOKING = "CUSTOMER_REPORT_BOOKING",
  CUSTOMER_CONFIRM_QUOTATION = "CUSTOMER_CONFIRM_QUOTATION",
  CUSTOMER_REVIEW_ORDER = "CUSTOMER_REVIEW_ORDER",
  CUSTOMER_REVIEW_BOOKING = "CUSTOMER_REVIEW_BOOKING",
  NEW_MESSAGE = "NEW_MESSAGE",
  USER_LIKE_POST = "USER_LIKE_POST",
  USER_COMMENT_POST = "USER_COMMENT_POST",
  UPCOMING_BOOKING = "UPCOMING_BOOKING",
  CUSTOMER_ACCEPT_RESCUE_RESPONSE = "CUSTOMER_ACCEPT_RESCUE_RESPONSE",
}
export interface INotification {
  id: string;
  type: NotificationTypeEnum;
  image: string;
  content: string;
  is_read: boolean;
  user_id: string;
  created_at: string;
  update_at: string;
  push_title: string;
  message: string;
  data: {
    message_id: string;
    conversation_id: string;
    booking_id: string;
    order_id: string;
    trailer_rescue_request_id: string;
    flash_buy_request_id: string;
  };
}

export interface IGetListNotificationsResponse extends CommonResponse {
  data: {
    notification_list: INotification[];
    total: number;
  };
}

export interface IUpdateNotificationsResponse extends CommonResponse {
  data: INotification;
}

export interface IGetCountNotificationResponse extends CommonResponse {
  data: number;
}
