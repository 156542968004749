const Colours = {
    BLUE: {
        3: '#F5FCFF',
        10: '#F6F7FE',
        50: '#BEDFFF',
        300: '#6781EE',
        1: '#17ABFF',
    },
    BLUE_CANDY: {
        10: '#F6F7FE',
    },
    PURPLE: {
        GRADIENT: ['#2CA9FF', '#0484FF'],
        10: '#FAFCFF',
        50: '#F1F8FF',
        100: '#C7E4FF',
        200: '#94CBFF',
        300: '#53ABFF',
        400: '#2A96FF',
        500: '#047BEE',
        600: '#0368C9',
        700: '#0354A2',
        800: '#024789',
        900: '#023464',
    },
    ORANGE: {
        GRADIENT: ['#FFB238', '#FF941E'],
        1: '#FF941E',
        2: '#FFC080',
        3: '#FFE6CC',
        TEXT: '#B76300',

    },
    GRAY: {
        50: '#F1F8FF',
        100: '#E2E4E9',
        200: '#CFD5DA',
        300: '#A3AEB7',
        400: '#708190',
        500: '#40576A',
        600: '#293846',
        700: '#182129',
    },

    BACKGROUND: {
        PRIMARY: '#F5F5F5',
        BLUE: '#F5FAFF',
        50: '#ECEEF0',
        100: '#D7DCE0',
        200: '#B0B8C0',
        300: '#8895A1',
        400: '#617281',
        500: '#394E62',
    },
    SHADE: {
        2: '#3A5065',
        3: '#899FB3',
        4: '#D1DCE7',
    },
    PRIMARY_TEXT: '#333333',
    ACCENT: '#FF941E',
    ERROR: '#F52727',
    CHOCOLATE: '#C76D03',
    GREEN: '#1ABD70',
    GREEN_TEXT: '#157146',
    TOAST_ERROR: '#FF4343',
    TOAST_ERROR_MSG: '#ffd9d9',
    TOAST_SUCCESS: '#3CD58D',
    TOAST_SUCCESS_MSG: '#d8f6e8',
    TOAST_WARNING: '#FF9900',
    TOAST_WARNING_MSG: '#ffefd6',
};

export default Colours