import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { IService, IServiceCategory } from "./type";

const { persistAtom } = recoilPersist();

interface ServiceList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IService[];
  loading: boolean;
  name?: string;
  category_id?: string;
}

interface ServiceDetails {
  details?: IService;
  loading: boolean;
}

interface FilesUpload {
  data?: {
    // success = 0 là failed, 1 là thành công
    success: 0 | 1;
    message: string;

    // success = 0
    data: {
      row: number;
      errors: string[];
    }[];

    // success = 1
    result: {
      success: 0 | 1;
      row: number;
      // if success = 0 thì sẽ trả ra lỗi, 1 là trả ra product
      data: IService | string;
    }[];
  };

  loading: boolean;
}

const list = atom<ServiceList>({
  key: "service_list",
  default: {
    page: 1,
    itemPerPage: 10,
    total: 0,
    data: [],
    loading: false,
  },
});

const fileUpload = atom<FilesUpload>({
  key: "service_upload",
  default: {
    loading: false,
  },
});

const details = atom<ServiceDetails>({
  key: "service_details",
  default: {
    loading: false,
  },
});

const serviceCategoryList = atom<IServiceCategory[]>({
  key: "serviceCategoryList",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const mostViewedServiceList = atom<IService[]>({
  key: "mostViewedServiceList",
  default: [],
});

const ServiceAtom = { list, details, serviceCategoryList, fileUpload, mostViewedServiceList };

export default ServiceAtom;
