import classnames from "classnames";
import React, { FC } from "react";
import Icon from "../Icon";
import "./Button.less";
import ReactLoading from "react-loading";

interface CustomButtonProps extends ButtonProps {
  minWidth?: number | string;
  maxWidth?: number;
  background?: string;
  color?: string;
}

const Button: FC<CustomButtonProps> = (props) => {
  const {
    className,
    fullWidth,
    text,
    icon,
    size = "normal",
    variant = "primary",
    htmlType = "button",
    loading,
    children,
    minWidth,
    maxWidth,
    background,
    color,
    ...rest
  } = props;

  return (
    <button
      disabled={loading}
      type={htmlType}
      style={{
        minWidth: minWidth,
        maxWidth: maxWidth,
        borderRadius: 12,
        backgroundColor: background,
        color: color
      }}
      className={classnames(`button button-${variant}`, className, size, {
        w__100: fullWidth
      })}
      {...rest}
    >
      {loading ? (
        <ReactLoading type="spin" color="white" width={16} height={16} />
      ) : children ? (
        children
      ) : (
        <React.Fragment>
          {icon?.includes("/") ? (
            <img src={icon} alt="" />
          ) : (
            icon && <Icon icon={icon} size={20} />
          )}
          {text && <span>{text}</span>}
        </React.Fragment>
      )}
    </button>
  );
};

export default Button;
