import React from "react";
import Layout from "components/Layout";

const RouterConfig = [
  {
    auth: true,
    layout: Layout,
    path: "*",
    component: React.lazy(() => import("pages/Home")),
  },
  {
    auth: false,
    path: "/sign-in",
    component: React.lazy(() => import("pages/Onboarding/SignIn")),
  },
  {
    auth: false,
    path: "/register",
    component: React.lazy(() => import("pages/Onboarding")),
  },
  {
    auth: false,
    path: "/forgot-password",
    component: React.lazy(() => import("pages/Onboarding/ForgotPassword")),
  },
  {
    auth: false,
    path: "/verify-otp",
    component: React.lazy(() => import("pages/Onboarding/VerifyOtp")),
  },
  // {
  //   auth: false,
  //   path: "/notification",
  //   component: React.lazy(() => import("pages/Onboarding/Notification")),
  // },
  // {
  //   auth: false,
  //   path: "/payment",
  //   component: React.lazy(() => import("pages/Onboarding/Payment")),
  // },
  //agency profile routes
  {
    auth: true,
    layout: Layout,
    path: "/agency",
    component: React.lazy(() => import("pages/AgentProfile/AgentInfo")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/agency/register",
    component: React.lazy(() => import("pages/AgentProfile/BusinessInfo")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/agency/beneficiary-bank",
    component: React.lazy(() => import("pages/AgentProfile/BeneficiaryBank")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/agency/bill-confirm",
    component: React.lazy(() => import("pages/AgentProfile/AgentBillConfirm")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/agency/change-password",
    component: React.lazy(() => import("pages/AgentProfile/ChangePassword")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/service",
    component: React.lazy(() => import("pages/Service")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/service/:id",
    component: React.lazy(() => import("pages/Service/ServiceDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/service/create",
    component: React.lazy(() => import("pages/Service/ServiceCreate")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/service-rescue",
    component: React.lazy(() => import("pages/ServiceRescue")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/product",
    component: React.lazy(() => import("pages/Product")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/product/:id",
    component: React.lazy(() => import("pages/Product/ProductDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/product/create",
    component: React.lazy(() => import("pages/Product/ProductCreate")),
  },

  //notify & message page
  {
    auth: true,
    layout: Layout,
    path: "/notify",
    component: React.lazy(() => import("pages/MessageAndNotify/Notify")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/system",
    component: React.lazy(() => import("pages/MessageAndNotify/System")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/message",
    component: React.lazy(() => import("pages/MessageAndNotify/Message")),
  },
  // revenue routes
  {
    auth: true,
    layout: Layout,
    path: "/revenue",
    component: React.lazy(() => import("pages/Revenue/Report")),
  },
  // {
  //   auth: true,
  //   layout: Layout,
  //   path: "/revenue/history",
  //   component: React.lazy(() => import("pages/Revenue/History")),
  // },
  {
    auth: true,
    layout: Layout,
    path: "/promotion",
    component: React.lazy(() => import("pages/Promotion")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/promotion/:id",
    component: React.lazy(() => import("pages/Promotion/PromotionDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/promotion/create",
    component: React.lazy(() => import("pages/Promotion/PromotionCreate")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-towing",
    component: React.lazy(() => import("pages/Rescue/RescueTowing")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-towing/:id",
    component: React.lazy(
      () => import("pages/Rescue/RescueTowing/RescueTowingDetail")
    ),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-repair",
    component: React.lazy(() => import("pages/Rescue/RescueRepair")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-repair/:id",
    component: React.lazy(
      () => import("pages/Rescue/RescueRepair/RescueRepairDetail")
    ),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-onsite",
    component: React.lazy(() => import("pages/Rescue/RescueOnsite")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/rescue-onsite/:id",
    component: React.lazy(
      () => import("pages/Rescue/RescueOnsite/RescueOnsiteDetail")
    ),
  },
  {
    auth: true,
    layout: Layout,
    path: "/flash-buy",
    component: React.lazy(() => import("pages/FlashBuy")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/flash-buy/:id",
    component: React.lazy(() => import("pages/FlashBuy/FlashBuyDetail")),
  },
  // manage customer
  {
    auth: true,
    layout: Layout,
    path: "/customer",
    component: React.lazy(() => import("pages/Customer")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/customer/:id",
    component: React.lazy(() => import("pages/Customer/CustomerDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/booking",
    component: React.lazy(() => import("pages/Booking")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/booking/:id",
    component: React.lazy(() => import("pages/Booking/BookingDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/booking/price/:id",
    component: React.lazy(() => import("pages/Booking/BookingPrice")),
  },
  // manage purchase
  {
    auth: true,
    layout: Layout,
    path: "/purchase",
    component: React.lazy(() => import("pages/ManagePurchase")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/purchase/:id",
    component: React.lazy(() => import("pages/ManagePurchase/PurchaseDetail")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/insurance",
    component: React.lazy(() => import("pages/InsuranceProduct")),
  },
  {
    auth: true,
    layout: Layout,
    path: "/insurance/:id",
    component: React.lazy(
      () => import("pages/InsuranceProduct/InsuranceProductDetail")
    ),
  },
  {
    auth: true,
    layout: Layout,
    path: "/insurance/create",
    component: React.lazy(
      () => import("pages/InsuranceProduct/InsuranceProductCreate")
    ),
  },
];

export default RouterConfig;
