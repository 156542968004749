import axios, { AxiosResponse, Method, ResponseType } from "axios";
import { setRecoil } from "recoil-nexus";
import KeywordAtom from "services/keyword/keyword.atom";
import { StorageConstant } from "services/storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_CARX_API,
  timeout: 15000,
  validateStatus: (status: number) => status < 400,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});

const CXApi = async <T>({
  url,
  method = "POST",
  data,
  params,
  responseType,
}: {
  url: string;
  method?: Method;
  data?: any;
  params?: any;
  responseType?: ResponseType;
}): Promise<T> => {
  return instance
    .request({
      method,
      url,
      data,
      params,
      responseType,
    })
    .then((resp: AxiosResponse<any>) => {
      return resp.data;
    })
    .catch((error) => {
      if (error.response?.data?.errorCode === "FORBIDDEN_KEYWORD_ERROR") {
        setRecoil(KeywordAtom.keyword, [error.response?.data?.data]);
      }
      const errorResponse = error as IError;
      return errorResponse.response?.data;
    });
};

export default CXApi;
