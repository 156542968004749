import { atom } from "recoil";
import { IProduct } from "./type";

interface ProductList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IProduct[];
  loading: boolean;
  name?: string;
}

interface ProductDetails {
  details?: IProduct;
  loading: boolean;
}

interface FilesUpload {
  data?: {
    // success = 0 là failed, 1 là thành công
    success: 0 | 1;
    message: string;

    // success = 0
    data: {
      row: number;
      errors: string[];
    }[];

    // success = 1
    result: {
      success: 0 | 1;
      row: number;
      // if success = 0 thì sẽ trả ra lỗi, 1 là trả ra product
      data: IProduct | string;
    }[];
  };

  loading: boolean;
}

const list = atom<ProductList>({
  key: "product_list",
  default: {
    page: 1,
    itemPerPage: 10,
    total: 0,
    data: [],
    loading: false,
  },
});

const fileUpload = atom<FilesUpload>({
  key: "product_upload",
  default: {
    loading: false,
  },
});

const details = atom<ProductDetails>({
  key: "product_details",
  default: {
    loading: false,
  },
});

const mostViewedProductList = atom<IProduct[]>({
  key: "mostViewedProductList",
  default: [],
});

const ProductAtom = { list, details, fileUpload, mostViewedProductList };

export default ProductAtom;
