import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import RescueAtom from "./rescue.atom";
import {
  IGetCountOnsiteResponse,
  IGetCountTowingRepairResponse,
  IGetDetailOnsiteRescueResponse,
  IGetDetailRepairRescueResponse,
  IGetListOnsiteRescueResponse,
  IGetListRepairRescueResponse,
  IGetListTowingRescueResponse,
  RescueRequestStatus,
} from "./type";

const addOnsiteRescue = (
  agent_id: string,
  onsite_rescue_request_id: string
) => {
  return CXApi<CommonResponse>({
    url: "/v1/onsite-rescue-responses",
    method: "POST",
    data: {
      agent_id,
      onsite_rescue_request_id,
    },
  });
};

const addOnsiteRescueReject = async (
  agent_id: string,
  onsite_rescue_request_id: string
) => {
  const res = await CXApi<CommonResponse>({
    url: "/v1/onsite-rescue-responses/reject",
    method: "POST",
    data: {
      agent_id,
      onsite_rescue_request_id,
    },
  });

  if (res.statusCode === 200) {
    return res;
  }

  return undefined;
};

const getOnsiteList = async (
  page: number,
  itemPerPage: number,
  agent_id?: string,
  name?: string
) => {
  setRecoil(RescueAtom.onsiteList, {
    ...getRecoil(RescueAtom.onsiteList),
    loading: true,
  });

  const params = {
    page,
    limit: itemPerPage,
    name,
    agent_id,
    status: RescueRequestStatus.SENT,
  };

  !name && delete params.name;

  const res = await CXApi<IGetListOnsiteRescueResponse>({
    url: "/v1/onsite-rescue-requests",
    method: "GET",
    params,
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.onsiteList, {
      ...getRecoil(RescueAtom.onsiteList),
      loading: false,
      total: res.data.total,
      data: res.data.onsite_rescue_request_list,
    });

    return res.data.onsite_rescue_request_list;
  }

  setRecoil(RescueAtom.onsiteList, {
    ...getRecoil(RescueAtom.onsiteList),
    loading: false,
  });
  return undefined;
};

const getOnsiteDetails = async (id: string) => {
  setRecoil(RescueAtom.onsiteDetails, {
    ...getRecoil(RescueAtom.onsiteDetails),
    loading: true,
  });

  const res = await CXApi<IGetDetailOnsiteRescueResponse>({
    url: "/v1/onsite-rescue-requests/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.onsiteDetails, {
      ...getRecoil(RescueAtom.onsiteDetails),
      details: res.data,
      loading: false,
    });
    return res.data;
  }

  return undefined;
};

const getCountOnsite = async (agent_id: string) => {
  const res = await CXApi<IGetCountOnsiteResponse>({
    url: "/v1/onsite-rescue-requests/agents/" + agent_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return 0;
};

const addTowingRescue = async (
  agent_id: string,
  trailer_rescue_request_id: string,
  trailer_later_rescue_response_service_info: {
    price: string;
  }
) => {
  return CXApi<CommonResponse>({
    url: "/v1/trailer-later-rescue-responses",
    method: "POST",
    data: {
      agent_id,
      trailer_rescue_request_id,
      trailer_later_rescue_response_service_info,
    },
  });
};

const addTowingRescueReject = async (
  agent_id: string,
  trailer_rescue_request_id: string
) => {
  const res = await CXApi<CommonResponse>({
    url: "/v1/trailer-later-rescue-responses/reject",
    method: "POST",
    data: {
      agent_id,
      trailer_rescue_request_id,
    },
  });

  if (res.statusCode === 200) {
    return res;
  }

  return undefined;
};

const getTowingList = async (
  page: number,
  itemPerPage: number,
  later_agent_id?: string,
  name?: string
) => {
  setRecoil(RescueAtom.towingList, {
    ...getRecoil(RescueAtom.towingList),
    loading: true,
  });

  let params = {
    page,
    limit: itemPerPage,
    name,
    later_agent_id,
    later_status: "SENT",
    former_status: "PROCESSING",
  };

  !name && delete params.name;

  const res = await CXApi<IGetListTowingRescueResponse>({
    url: "/v1/trailer-rescue-requests",
    method: "GET",
    params,
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.towingList, {
      ...getRecoil(RescueAtom.towingList),
      loading: false,
      total: res.data.total,
      data: res.data.trailer_rescue_request_list,
    });

    return res.data.trailer_rescue_request_list;
  }

  setRecoil(RescueAtom.towingList, {
    ...getRecoil(RescueAtom.towingList),
    loading: false,
  });
  return undefined;
};

const getTowingDetails = async (id: string) => {
  setRecoil(RescueAtom.towingDetails, {
    ...getRecoil(RescueAtom.towingDetails),
    loading: true,
  });

  const res = await CXApi<IGetDetailOnsiteRescueResponse>({
    url: "/v1/trailer-rescue-requests/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.towingDetails, {
      ...getRecoil(RescueAtom.towingDetails),
      details: res.data,
      loading: false,
    });
    return res.data;
  }

  return undefined;
};

const addRepairRescue = (
  agent_id: string,
  trailer_rescue_request_id: string
) => {
  return CXApi<CommonResponse>({
    url: "/v1/trailer-former-rescue-responses",
    method: "POST",
    data: {
      agent_id,
      trailer_rescue_request_id,
    },
  });
};

const addRepairRescueReject = async (
  agent_id: string,
  trailer_rescue_request_id: string
) => {
  const res = await CXApi<CommonResponse>({
    url: "/v1/trailer-former-rescue-responses/reject",
    method: "POST",
    data: {
      agent_id,
      trailer_rescue_request_id,
    },
  });

  if (res.statusCode === 200) {
    return res;
  }

  return undefined;
};

const getRepairList = async (
  page: number,
  itemPerPage: number,
  former_agent_id?: string,
  name?: string
) => {
  setRecoil(RescueAtom.repairList, {
    ...getRecoil(RescueAtom.repairList),
    loading: true,
  });

  let params = {
    page,
    limit: itemPerPage,
    name,
    former_agent_id,
    former_status: "SENT",
    later_status: "UNSENT",
  };

  !name && delete params.name;

  const res = await CXApi<IGetListRepairRescueResponse>({
    url: "/v1/trailer-rescue-requests",
    method: "GET",
    params,
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.repairList, {
      ...getRecoil(RescueAtom.repairList),
      loading: false,
      total: res.data.total,
      data: res.data.trailer_rescue_request_list,
    });

    return res.data.trailer_rescue_request_list;
  }

  setRecoil(RescueAtom.repairList, {
    ...getRecoil(RescueAtom.repairList),
    loading: false,
  });
  return undefined;
};

const getRepairDetails = async (id: string) => {
  setRecoil(RescueAtom.repairDetails, {
    ...getRecoil(RescueAtom.repairDetails),
    loading: true,
  });

  const res = await CXApi<IGetDetailRepairRescueResponse>({
    url: "/v1/trailer-rescue-requests/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(RescueAtom.repairDetails, {
      ...getRecoil(RescueAtom.repairDetails),
      details: res.data,
      loading: false,
    });
    return res.data;
  }

  return undefined;
};

const getCountTowingRepair = async (agent_id: string) => {
  const res = await CXApi<IGetCountTowingRepairResponse>({
    url: "/v1/trailer-rescue-requests/agents/" + agent_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return 0;
};

const RescueApi = {
  addOnsiteRescue,
  addTowingRescue,
  addOnsiteRescueReject,
  addTowingRescueReject,
  getOnsiteList,
  getTowingList,
  getOnsiteDetails,
  getTowingDetails,
  addRepairRescue,
  addRepairRescueReject,
  getRepairList,
  getRepairDetails,
  getCountOnsite,
  getCountTowingRepair,
};

export default RescueApi;
