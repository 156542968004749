import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import Button from "components/Button";
import LogoKeyword from "assets/logo-keyword.svg";
import ModalContainer from "components/ModalContainer";
import PopupAtom from "services/keyword/keyword.atom";

import "./PopupKeyword.less";

const PopupKeyword: React.FC = () => {
  const keywordList = useRecoilValue(PopupAtom.keyword);
  const resetKeyword = useResetRecoilState(PopupAtom.keyword);

  return (
    <ModalContainer
      open={keywordList.length > 0}
      onClose={resetKeyword}
      title="Thông báo"
    >
      <div className="popup-keyword">
        <div className="content">
          <img src={LogoKeyword} alt="" />
          <h2 className="title">
            Nội dung của bạn có từ khoá
            <br />
            không phù hợp
          </h2>

          <div className="keywords">
            <span>Từ khoá:</span> "
            {keywordList.map((keyword, index) => (
              <React.Fragment key={index}>
                <span>{keyword}</span>
                {index !== keywordList.length - 1 && <span>, </span>}
              </React.Fragment>
            ))}
            "
          </div>

          <p className="description">
            Vui lòng thay thế từ khoá không phù hợp để
            <br />
            hoàn tất thao tác của bạn
          </p>
        </div>

        <Button
          text="Xác nhận"
          style={{ width: 235, height: 46 }}
          onClick={resetKeyword}
        />
      </div>
    </ModalContainer>
  );
};

export default PopupKeyword;
