export enum SocketChannelEnum {
  CARX_NOTIFICATION = "CARX_NOTIFICATION",
  CARX_PRODUCT = "CARX_PRODUCT",
  CARX_SERVICE = "CARX_SERVICE",
  CARX_MESSAGES = "CARX_MESSAGES",
}

export enum TransactionStatusEnum {
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export enum TransactionPaymentMethodEnum {
  CASH = "CASH",
  MOMO_E_WALLET = "MOMO_E_WALLET",
  MOMO_ATM = "MOMO_ATM",
  MOMO_CREDIT_CARD = "MOMO_CREDIT_CARD",
}

export enum TransactionPaymentTypeEnum {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export interface ITransaction {
  id: string;
  order_id: string;
  status: TransactionStatusEnum;
  payment_method: TransactionPaymentMethodEnum;
  payment_type: TransactionPaymentTypeEnum;
  agent_id: string;
  customer_id: string;
  created_at: string;
  updated_at: string;
}

export interface SocketProps {
  channel: SocketChannelEnum;
  action: string;
  data: any;
}

export interface RoutesProps {
  name: string;
  path: string;
  count?: number;
}

export interface IProduct {
  name: string;
  price?: number;
  quantity?: number;

  lisImageGallery?: Array<string>;
  id?: string | number;
  image?: string;
  time?: string;
  comment?: string;
  category?: string;
  color?: string;
  description?: string;
  status?: "pending" | "accept";
  customer?: IUser;
  view_count?: number;
}

export interface ICart {
  products: IProduct[];
  saleOff: number;
  date?: string;
}
export interface IBill {
  id: string;
  name: string;
  phone: string;
  code: string;
  address: string;
  carts: ICart;
}

export interface IUser {
  avatar: string;
  name: string;
  phone?: string;
  rank?: string;
}

//Bill
export interface ICard {
  bankName: string;
  last4: string;
}

export interface IBillContent {
  title: string;
  card: ICard;
  id: string;
  amount: number;
}
export interface IBankingBill {
  date: string;
  billDetails: IBillContent[];
}

export interface IRadio {
  id: number;
  text: string;
  checked: boolean;
}
