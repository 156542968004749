import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { IUserAtom } from "./type";

const { persistAtom } = recoilPersist();

const userInfo = atom<IUserAtom>({
  key: "userInfo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const UserAtom = { userInfo };

export default UserAtom;
