import classNames from "classnames";
import Icon from "components/Icon";
import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationApi, NotificationAtom } from "services/notification";
import {
  INotification,
  NotificationTypeEnum,
} from "services/notification/type";
import formatDate from "utils/formatDate";
import "./NotifyItem.less";
import NotificationDefault from "assets/notification_default.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserAtom } from "services/user";
import NewsAtom from "services/news/news.atom";
import Colours from "data/Colours";
import ReactTimeAgo from "react-time-ago";

interface NotifyItemProps {
  notify: INotification;
  style?: CSSProperties;
  variant?: string;
}

const NotifyItem: React.FC<NotifyItemProps> = ({ notify, style, variant }) => {
  const nav = useNavigate();
  const user = useRecoilValue(UserAtom.userInfo);
  const [notificationList, setNotificationList] = useRecoilState(
    NotificationAtom.notificationList
  );
  const [notificationSystemList, setNotificationSystemList] = useRecoilState(
    NotificationAtom.notificationSystemList
  );
  const setNewsDetail = useSetRecoilState(NewsAtom.newsDetail);

  const handleNavigate = () => {
    !notify.is_read &&
      NotificationApi.updateNotificaton(notify.id).then(() => {
        user.id && NotificationApi.getCountNotification(user.id);

        notificationList.data.some(
          (notification) => notification.id === notify.id
        ) &&
          setNotificationList({
            ...notificationList,
            data: notificationList.data.map((notification) => {
              if (notification.id === notify.id) {
                return { ...notification, is_read: true };
              }
              return notification;
            }),
          });

        notificationSystemList.data.some(
          (notification) => notification.id === notify.id
        ) &&
          setNotificationSystemList({
            ...notificationSystemList,
            data: notificationSystemList.data.map((notification) => {
              if (notification.id === notify.id) {
                return { ...notification, is_read: true };
              }
              return notification;
            }),
          });
      });
    switch (notify.type) {
      case NotificationTypeEnum.NEW_MESSAGE: {
        nav("/message?id=" + notify.data.conversation_id);
        break;
      }
      case NotificationTypeEnum.ORDER_COMPLETED:
      case NotificationTypeEnum.ORDER_DELIVERING:
      case NotificationTypeEnum.CUSTOMER_CANCEL_ORDER:
      case NotificationTypeEnum.CUSTOMER_CREATE_ORDER:
      case NotificationTypeEnum.CUSTOMER_REPORT_ORDER:
      case NotificationTypeEnum.CUSTOMER_REVIEW_ORDER: {
        nav("/purchase/" + notify.data.order_id);
        break;
      }
      case NotificationTypeEnum.CUSTOMER_CREATE_BOOKING:
      case NotificationTypeEnum.UPCOMING_BOOKING:
      case NotificationTypeEnum.CUSTOMER_CANCEL_BOOKING:
      case NotificationTypeEnum.CUSTOMER_CONFIRM_QUOTATION:
      case NotificationTypeEnum.CUSTOMER_REPORT_BOOKING:
      case NotificationTypeEnum.CUSTOMER_REVIEW_BOOKING:
      case NotificationTypeEnum.BOOKING_WAITING_FOR_PAYMENT:
      case NotificationTypeEnum.BOOKING_COMPLETED: {
        nav("/booking/" + notify.data.booking_id);
        break;
      }
      case NotificationTypeEnum.CARX: {
        setNewsDetail({
          push_title: notify?.push_title,
          content: notify?.content,
          created_at: notify?.created_at,
        });
        break;
      }
      case NotificationTypeEnum.CUSTOMER_ACCEPT_FLASH_BUY_RESPONSE:
      case NotificationTypeEnum.CUSTOMER_CREATE_FLASH_BUY_REQUEST: {
        nav("/flash-buy/" + notify.data.flash_buy_request_id);
        break;
      }
      case NotificationTypeEnum.CUSTOMER_ACCEPT_RESCUE_RESPONSE: {
        nav("/rescue-towing/" + notify.data.trailer_rescue_request_id);
        break;
      }
      case NotificationTypeEnum.CUSTOMER_CREATE_RESCUE_REQUEST: {
        break;
      }
    }
  };

  if (variant === 'primary') {
    return (
      <div onClick={handleNavigate} className="noti_item row al-center ju-between mb-20" style={{ cursor: 'pointer', backgroundColor: !notify.is_read ? Colours.BLUE[10] : 'transparent' }}>
        <div className="info row gap-12">
          <img src={notify.image} alt="" width={52} height={52} style={{ borderRadius: 8 }} />
          <div style={{ wordWrap: 'break-word', width: '27rem' }}>
            <p className="small_title mb-8">
              {notify.message}
            </p>
            <p className="sub_text row al-center gap-4">
              <Icon icon='clock' size={13} color={Colours.GRAY[400]} />               <ReactTimeAgo
                date={new Date(notify.created_at)}
                className="time"
              />
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={style}
      className={classNames("notification-item", {
        active: !notify.is_read,
      })}
      onClick={handleNavigate}
    >
      <img src={notify.image || NotificationDefault} alt={notify.push_title} />
      <div className="content">
        <div className="title">{notify.message}</div>
        <div className="time">
          <Icon icon="clock" size={13} color="#7F859F" />
          <span>{formatDate(notify.created_at, "dd/M/yyyy HH:mm")}</span>
        </div>
      </div>
      {/* <Icon icon="more" className="more" /> */}
    </div>
  );
};

export default NotifyItem;
