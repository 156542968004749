import { atom } from "recoil";

const isSidebar = atom<boolean>({
  key: "isSidebar",
  default: true,
});

const SidebarAtom = { isSidebar };

export default SidebarAtom;
