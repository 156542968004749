import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import ChatAtom from "./chat.atom";
import {
  IAddMessageResponse,
  IGetDetailConversationsResponse,
  IGetListConversationsResponse,
  IGetListMessagesResponse,
  IMessageType,
} from "./type";

const getConversationList = async (
  page: number,
  itemPerPage: number,
  agent_id: string
) => {
  setRecoil(ChatAtom.convertsationList, {
    ...getRecoil(ChatAtom.convertsationList),
    loading: true,
  });

  const res = await CXApi<IGetListConversationsResponse>({
    url: "/v1/chat-conversations",
    method: "GET",
    params: {
      page,
      limit: itemPerPage,
      agent_id,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(ChatAtom.convertsationList, {
      ...getRecoil(ChatAtom.convertsationList),
      loading: false,
      page,
      itemPerPage,
      totalPage: Math.ceil(res.data.total / itemPerPage),
      data:
        page === 1
          ? res.data.conversation_list
          : [
              ...getRecoil(ChatAtom.convertsationList).data,
              ...res.data.conversation_list,
            ],
    });

    return res.data.conversation_list;
  }

  return undefined;
};

const getConversationDetails = async (id: string) => {
  setRecoil(ChatAtom.conversationDetails, undefined);

  const res = await CXApi<IGetDetailConversationsResponse>({
    url: "/v1/chat-conversations/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(ChatAtom.conversationDetails, res.data);
    return res.data;
  }

  return undefined;
};

const getMessageList = async (
  page: number,
  itemPerPage: number,
  conversation_id: string
) => {
  setRecoil(ChatAtom.messageList, {
    ...getRecoil(ChatAtom.messageList),
    loading: true,
  });

  const res = await CXApi<IGetListMessagesResponse>({
    url: "/v1/chat-messages",
    method: "GET",
    params: {
      page,
      limit: itemPerPage,
      conversation_id,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(ChatAtom.messageList, {
      ...getRecoil(ChatAtom.messageList),
      loading: false,
      page,
      itemPerPage,
      totalPage: Math.ceil(res.data.total / itemPerPage),
      data:
        page === 1
          ? res.data.message_list.reverse()
          : [
              ...res.data.message_list.reverse(),
              ...getRecoil(ChatAtom.messageList).data,
            ],
    });

    return res.data.message_list;
  }

  return undefined;
};

const addMessage = async (
  conversation_id: string,
  sender_id: string,
  receiver_id: string,
  message: {
    content: string;
    type: IMessageType;
  }
) => {
  const res = await CXApi<IAddMessageResponse>({
    url: "/v1/chat-messages",
    method: "POST",
    data: {
      conversation_id,
      sender_id,
      receiver_id,
      message,
    },
  });

  if (res.statusCode === 200) {
    return res;
  }

  return undefined;
};

const ChatApi = {
  getConversationList,
  getMessageList,
  addMessage,
  getConversationDetails,
};

export default ChatApi;
