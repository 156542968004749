import React, { useEffect, useState } from "react";

const useClickOutSide = (htmlNode?: any) => {
  const nodeRef = React.useRef<any>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (typeof htmlNode === "string") {
        nodeRef.current &&
          !nodeRef.current.contains(e.target) &&
          !e.target.matches(htmlNode) &&
          setShow(false);
      } else {
        nodeRef.current &&
          !nodeRef.current.contains(e.target) &&
          setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutSide);

    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  }, [htmlNode]);

  return {
    nodeRef,
    show,
    setShow,
  };
};

export default useClickOutSide;
