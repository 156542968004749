import React, { CSSProperties } from "react";
import avatarDefault from "assets/avatar.svg";
import "./Avatar.less";
import classNames from "classnames";

interface AvatarProps {
  src?: string;
  alt?: string;
  className?: string;
  style?: CSSProperties;
  size?: number;
}

const Avatar: React.FC<AvatarProps> = ({ src, alt, style, className, size = 48 }) => (
  <img
    className={classNames("avatar-image", className)}
    src={src || avatarDefault}
    alt={alt || ""}
    width={size}
    height={size}
    style={style}
  />
);

export default Avatar;
