import { CXApi } from "services/api";
import { IGetDetailUserResponse } from "./type";

const getDetailUser = (id: string) => {
  return CXApi<IGetDetailUserResponse>({
    url: "/v1/users/" + id,
    method: "GET",
  });
};

const UserApi = {
  getDetailUser,
};

export default UserApi;
