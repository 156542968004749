import { atom } from "recoil";
import { INotification } from "./type";

interface NotificationList {
  page: number;
  itemPerPage: number;
  totalPage: number;
  data: INotification[];
  loading: boolean;
  count: number;
}

const notificationList = atom<NotificationList>({
  key: "notification_list",
  default: {
    page: 1,
    itemPerPage: 15,
    totalPage: 0,
    data: [],
    loading: false,
    count: 0,
  },
});

const notificationSystemList = atom<NotificationList>({
  key: "notification_system_list",
  default: {
    page: 1,
    itemPerPage: 15,
    totalPage: 0,
    data: [],
    loading: false,
    count: 0,
  },
});

const NotificationAtom = {
  notificationList,
  notificationSystemList,
};

export default NotificationAtom;
