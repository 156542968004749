import { atom } from "recoil";
import { INews } from "./type";

const newsDetail = atom<INews | undefined>({
  key: "news_detail",
  default: undefined,
});

const NewsAtom = {
  newsDetail,
};

export default NewsAtom;
