import React from "react";
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";

interface IconProps {
  color?: string;
  size?: number;
  icon: string;
  className?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = (props) => {
  const { color, size, icon, className, ...rest } = props;
  return (
    <div className={`icon ${className}`}>
      <IcomoonReact
        iconSet={iconSet}
        color={color ?? "#444"}
        size={size ?? 100}
        icon={icon}
        {...rest}
      />
    </div>
  );
};

export default Icon;
