import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./config/ProtectedRoute";
import RouterConfig from "./routes";
import PublicRoute from "./config/PublicRoute";
import { useRecoilValue } from "recoil";
import { OnBoardingAtom } from "services/onboarding";

const Routers: FC = () => {
  const agent = useRecoilValue(OnBoardingAtom.agentInfo);
  const insuranceId = "65a347b9-3c84-4e23-9856-c7245d7bdffd";

  let routerConfigs = [...RouterConfig];

  if (insuranceId === agent.category_id) {
    routerConfigs = routerConfigs.filter((router) => {
      return (
        !router.path.includes("service") &&
        !router.path.includes("product") &&
        !router.path.includes("booking") &&
        !router.path.includes("rescue") &&
        !router.path.includes("flash-buy")
      );
    });
  } else {
    routerConfigs = routerConfigs.filter(
      (router) => !router.path.includes("insurance")
    );
  }

  return (
    <Routes>
      {routerConfigs.map((route, index) => {
        const Layout = route.layout || React.Fragment;
        const Page = route.component;
        if (route.auth) {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  <Layout>
                    <Page />
                  </Layout>
                </ProtectedRoute>
              }
            />
          );
        }
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <PublicRoute>
                <Layout>
                  <Page />
                </Layout>
              </PublicRoute>
            }
          />
        );
      })}
    </Routes>
  );
};

export default Routers;
