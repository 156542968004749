import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import {
  IAgentCategory,
  IAgentPayload,
  IBusinessPayload,
  IPaymentMethod,
} from "./type";

const { persistAtom } = recoilPersist();

interface AgentCategoryProps {
  data: IAgentCategory[];
  loading: boolean;
}

const businessInfo = atom<IBusinessPayload>({
  key: "businessInfo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const agentInfo = atom<IAgentPayload>({
  key: "agentInfo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const agentCategory = atom<AgentCategoryProps>({
  key: "agentCategory",
  default: {
    data: [],
    loading: false,
  },
});

const paymentMethods = atom<IPaymentMethod[]>({
  key: "paymentMethods",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const OnboardingAtom = {
  businessInfo,
  agentInfo,
  agentCategory,
  paymentMethods,
};

export default OnboardingAtom;
