import { FC } from "react";
import dataNone from "assets/data-none.png";
import "./DataNonePage.less";

interface Props {
  title?: string
}

const DataNonePage: FC<Props> = ({ title = 'Hiện chưa có dữ liệu' }) => {
  return (
    <div className="data-none-page">
      <img src={dataNone} alt="data none" />
      {title && <span>{title}</span>}
    </div>
  );
};

export default DataNonePage;
