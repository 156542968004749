import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import OnboardingAtom from "./onboarding.atom";
import {
  IAgentPayload,
  IBusinessPayload,
  IGetAgentCategoryResponse,
  IGetDetailAgentResponse,
  IGetDetailCompanyResponse,
  IGetPaymentMethodsResponse,
  IUpdateAgentResponse,
  IUpdateCompanyResponse,
} from "./type";

const getDetailCompany = (id: string) => {
  return CXApi<IGetDetailCompanyResponse>({
    url: "/v1/companies/" + id,
    method: "GET",
  });
};

const updateBusinessInfo = (id: string, payload: IBusinessPayload) => {
  return CXApi<IUpdateCompanyResponse>({
    url: "/v1/companies/" + id,
    method: "PUT",
    data: {
      address: payload.address,
      email: payload.email,
      license: payload.license,
      name: payload.name,
      other_info: payload.other_info,
      phone_number: payload.phone_number,
      size: payload.size,
      tax_id: payload.tax_id,
    },
  });
};

const getDetailAgent = (id: string) => {
  return CXApi<IGetDetailAgentResponse>({
    url: "/v1/agents/" + id,
    method: "GET",
  });
};

const updateAgentInfo = (id: string, payload: IAgentPayload) => {
  return CXApi<IUpdateAgentResponse>({
    url: "/v1/agents/" + id,
    method: "PUT",
    data: {
      address: payload.address,
      avatar: payload.avatar,
      description: payload.description,
      images: payload.images,
      name: payload.name,
      payment_method: payload.payment_method,
      category_id: payload.category_id,
    },
  });
};

const updateBankAgent = (id: string, payload: IBanking[]) => {
  return CXApi<IUpdateAgentResponse>({
    url: "/v1/agents/" + id,
    method: "PUT",
    data: {
      payment_method: payload,
    },
  });
};

const getAgentCategory = async (page = 1, limit = 50) => {
  setRecoil(OnboardingAtom.agentCategory, {
    ...getRecoil(OnboardingAtom.agentCategory),
    loading: true,
  });
  const res = await CXApi<IGetAgentCategoryResponse>({
    url: "/v1/agent-categories",
    method: "GET",
    params: {
      page,
      limit,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(OnboardingAtom.agentCategory, {
      ...getRecoil(OnboardingAtom.agentCategory),
      loading: false,
      data: res.data.agent_category_list,
    });
    return res.data.agent_category_list;
  }

  return undefined;
};

const getPaymentMethods = async () => {
  const res = await CXApi<IGetPaymentMethodsResponse>({
    url: "/v1/payment-methods",
    method: "GET",
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return undefined;
};

const OnboardingApi = {
  updateBankAgent,
  updateBusinessInfo,
  updateAgentInfo,
  getAgentCategory,
  getPaymentMethods,
  getDetailAgent,
  getDetailCompany,
};

export default OnboardingApi;
