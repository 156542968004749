import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { StorageConstant } from "services/storage";
import { UserAtom } from "services/user";

const PublicRoute: React.FC = ({ children }) => {
  const user = useRecoilValue(UserAtom.userInfo);
  const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);

  if (
    user.is_verified &&
    token &&
    user.agent
    // user.company?.tax_id &&
    // user.agent?.id
  ) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
