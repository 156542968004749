import { atom } from "recoil";
import { IFlashBuy } from "./type";

interface FlashBuyList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IFlashBuy[];
  loading: boolean;
  product_name?: string;
}
interface FlashBuyDetails {
  loading: boolean;
  details?: IFlashBuy;
}

const list = atom<FlashBuyList>({
  key: "flashbuy_list",
  default: {
    page: 1,
    itemPerPage: 15,
    total: 0,
    data: [],
    loading: false,
    product_name: undefined,
  },
});

const details = atom<FlashBuyDetails>({
  key: "flashbuy_details",
  default: {
    loading: false,
  },
});

const FlashBuyAtom = {
  list,
  details,
};

export default FlashBuyAtom;
