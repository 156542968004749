import { atom } from "recoil";

const keyword = atom<string[]>({
  key: "keyword_list",
  default: [],
});

const KeywordAtom = { keyword };

export default KeywordAtom;
