import { Dialog } from "@mui/material";
import classNames from "classnames";
import Icon from "components/Icon";
import * as React from "react";
import "./ModalContainer.less";

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  titleAlign?: "left" | "center";
  open: boolean;
  width?: number;
  height?: number;
  onClose?: () => void;
}

const ModalContainer: React.FC<ModalProps> = ({
  children,
  title,
  width,
  height,
  onClose,
  titleAlign = "center",
  open = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "max-content",
          borderRadius: "1.6rem",
        },
      }}
    >
      <div className="modal-container" style={{ height, width }}>
        <div className="modal-container-header">
          {title && (
            <h2 className={classNames("title", titleAlign)}>{title}</h2>
          )}
          <Icon
            className="icon"
            icon="close"
            size={15}
            color=""
            onClick={onClose}
          />
        </div>
        {children}
      </div>
    </Dialog>
  );
};

export default ModalContainer;
