import { IBookingStatuses } from "services/booking/type";

export enum RescueResponseStatus {
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
}

export enum RescueTypes {
  ONSITE = "onsite",
  TOWING = "towing",
  REPAIR = "repair",
}

export enum RescueRequestStatus {
  SENT = "SENT",
}

export interface IRescue {
  id: string;
  customer_id: string;
  agent_id: string;
  booking_id: string;
  rescue_reason: string;
  cancel_reason: string;
  status: IBookingStatuses;
  car_info: {
    brand: string;
    color: string;
    car_no: string;
    vin_no: string;
    tire_no: string;
    model_name: string;
    model_year: number;
  };
  customer_info: {
    address: string;
    full_name: string;
    phone_number: number;
    current_location_geo: {
      url: string;
      name: string;
      compound: {
        commune: string;
        district: string;
        province: string;
      };
      geometry: {
        location: {
          lat: number;
          lng: number;
        };
      };
      place_id: string;
      plus_code: {
        global_code: string;
        compound_code: string;
      };
      formatted_address: string;
    };
  };
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  customer: {
    id: string;
    full_name: string;
    phone_number: number;
    avatar: string;
    car_details: {
      id: string;
      brand: string;
      model_name: string;
      model_year: number;
      car_no: string;
      color: string;
      tire_no: string;
      vin_no: string;
    };
  };
  responses: {
    id: string;
    onsite_rescue_request_id: string;
    agent_id: string;
    status: RescueResponseStatus;
    service_id: string;
    created_at: string;
    updated_at: string;
  }[];
  former_responses: {
    agent_id: string;
    created_at: string;
    id: string;
    service_id: string;
    status: RescueResponseStatus;
    trailer_rescue_request_id: string;
    updated_at: string;
  }[];
  later_responses: {
    agent_id: string;
    created_at: string;
    id: string;
    service_id: string;
    status: RescueResponseStatus;
    trailer_rescue_request_id: string;
    updated_at: string;
  }[];
}

export interface IGetListOnsiteRescueResponse extends CommonResponse {
  data: {
    onsite_rescue_request_list: IRescue[];
    total: number;
  };
}

export interface IGetListTowingRescueResponse extends CommonResponse {
  data: {
    trailer_rescue_request_list: IRescue[];
    total: number;
  };
}

export interface IGetListRepairRescueResponse extends CommonResponse {
  data: {
    trailer_rescue_request_list: IRescue[];
    total: number;
  };
}

export interface IGetCountOnsiteResponse extends CommonResponse {
  data: number;
}

export interface IGetCountTowingRepairResponse extends CommonResponse {
  data: number;
}

export interface IGetDetailOnsiteRescueResponse extends CommonResponse {
  data: IRescue;
}

export interface IGetDetailTowingRescueResponse extends CommonResponse {
  data: IRescue;
}

export interface IGetDetailRepairRescueResponse extends CommonResponse {
  data: IRescue;
}
