import { getRecoil, setRecoil } from "recoil-nexus";
import { CXApi } from "services/api";
import ServiceAtom from "./service.atom";
import {
  ICreateServiceResponse,
  IDeleteManyServiceResponse,
  IDeleteServiceResponse,
  IGetDetailServiceResponse,
  IGetListServiceRescueResponse,
  IGetListServiceResponse,
  IGetServiceCategoryResponse,
  IGetServiceTemplatesResponse,
  IService,
  IServiceStatus,
  IUpdateServiceResponse,
  IUploadFileServiceResponse,
} from "./type";

const addService = (data: IService, is_rescue_service = false) => {
  return CXApi<ICreateServiceResponse>({
    url: "/v1/services",
    method: "POST",
    data: { ...data, is_rescue_service },
  });
};

const uploadFileService = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const res = await CXApi<IUploadFileServiceResponse>({
    url: "/v1/services/bulk-upload",
    method: "POST",
    data: formData,
  });

  if (res.statusCode === 200) {
    return res.message;
  }

  return undefined;
};

const updateService = (id: string, data: IService) => {
  return CXApi<IUpdateServiceResponse>({
    url: "/v1/services/" + id,
    method: "PUT",
    data,
  });
};

const updateStatusService = (id: string, status: IServiceStatus) => {
  return CXApi<IUpdateServiceResponse>({
    url: "/v1/services/" + id,
    method: "PUT",
    data: { status },
  });
};

const getListService = async (
  page: number,
  itemPerPage: number,
  agent_id?: string,
  name?: string,
  category_id?: string,
  saveStore = true
) => {
  setRecoil(ServiceAtom.list, {
    ...getRecoil(ServiceAtom.list),
    loading: true,
  });

  let _params = {
    page: page,
    limit: itemPerPage,
    agent_id,
    name,
    category_id,
    is_rescue_service: false,
    is_status_included: false,
  };

  !category_id && delete _params.category_id;
  !name && delete _params.name;

  const res = await CXApi<IGetListServiceResponse>({
    url: "/v1/services",
    method: "GET",
    params: _params,
  });

  if (res.statusCode === 200) {
    saveStore &&
      setRecoil(ServiceAtom.list, {
        ...getRecoil(ServiceAtom.list),
        loading: false,
        total: res.data.total,
        category_id,
        data: res.data.service_list,
      });
    return res.data;
  }

  setRecoil(ServiceAtom.list, {
    ...getRecoil(ServiceAtom.list),
    loading: false,
  });
  return undefined;
};

const getMostViewedService = async (
  agent_id?: string,
) => {
  const res = await CXApi<any>({
    url: "/v1/services/most-viewed/" + agent_id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(ServiceAtom.mostViewedServiceList, res.data.service_list);
    return res.data.service_list;
  }

  return undefined;
};

const getListServiceRescue = async (
  page: number,
  itemPerPage: number,
  agent_id?: string,
  name?: string
) => {
  const res = await CXApi<IGetListServiceRescueResponse>({
    url: "/v1/services",
    method: "GET",
    params: {
      page: page,
      limit: itemPerPage,
      agent_id,
      is_rescue_service: true,
      name,
    },
  });

  if (res.statusCode === 200) {
    return res.data;
  }

  return undefined;
};

const getDetailService = async (id: string) => {
  setRecoil(ServiceAtom.details, {
    ...getRecoil(ServiceAtom.details),
    loading: true,
  });

  const res = await CXApi<IGetDetailServiceResponse>({
    url: "/v1/services/" + id,
    method: "GET",
  });

  if (res.statusCode === 200) {
    setRecoil(ServiceAtom.details, {
      details: res.data,
      loading: false,
    });
    return res.data;
  }

  return undefined;
};

const getServiceCategory = async () => {
  const res = await CXApi<IGetServiceCategoryResponse>({
    url: "/v1/service-categories",
    method: "GET",
    params: {
      page: 1,
      limit: 50,
    },
  });

  if (res.statusCode === 200) {
    setRecoil(ServiceAtom.serviceCategoryList, res.data.category_list);
    return res.data.category_list;
  }

  return undefined;
};

const deleteService = (id: string) => {
  return CXApi<IDeleteServiceResponse>({
    url: "/v1/services/" + id,
    method: "DELETE",
  });
};

const deleteManyService = (id: string[]) => {
  return CXApi<IDeleteManyServiceResponse>({
    url: "/v1/services/delete",
    method: "DELETE",
    data: id,
  });
};

const getServiceTemplates = (category_id: string) => {
  return CXApi<IGetServiceTemplatesResponse>({
    url: "/v1/service-templates",
    method: "GET",
    params: {
      category_id,
    },
  });
};

const ServiceApi = {
  updateStatusService,
  addService,
  updateService,
  getListService,
  getDetailService,
  getServiceCategory,
  deleteService,
  deleteManyService,
  getServiceTemplates,
  getListServiceRescue,
  uploadFileService,
  getMostViewedService
};

export default ServiceApi;
