import Icon from "components/Icon";
import ModalContainer from "components/ModalContainer";
import React from "react";
import ReactTimeAgo from "react-time-ago";
import { useRecoilValue } from "recoil";
import NewsAtom from "services/news/news.atom";
import "./ModalNews.less";

interface ModalNewsProps {
  visible: boolean;
  onClose: () => void;
}

const ModalNews: React.FC<ModalNewsProps> = ({ visible, onClose }) => {
  const newsDetail = useRecoilValue(NewsAtom.newsDetail);

  if (!newsDetail) return <React.Fragment></React.Fragment>;

  return (
    <ModalContainer
      title="Chi tiết bài viết"
      open={visible}
      onClose={onClose}
      width={750}
    >
      <div className="modal-news">
        <h3 className="title">{newsDetail.push_title}</h3>
        <div className="time">
          <Icon icon="clock" size={13} color="#7F859F" />

          <span>
            <ReactTimeAgo
              date={new Date(newsDetail.created_at)}
              className="time"
            />
          </span>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: newsDetail.content }}
          className="content"
        ></div>
      </div>
    </ModalContainer>
  );
};

export default ModalNews;
