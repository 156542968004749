import classNames from "classnames";
import DataNonePage from "components/DataNonePage";
import Icon from "components/Icon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IConversations } from "services/chat/type";
import MessageItem from "./MessageItem";
import "./Notification.less";

interface NotificationProps {
  sticky?: boolean;
  messages: IConversations[];
}

const MessageNotification: React.FC<NotificationProps> = ({
  messages,
  sticky = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="notification">
      <div className={classNames("title", { sticky: sticky ? "sticky" : "" })}>
        <div className="title_list">
          <span className="active">Tin nhắn</span>
        </div>
        <span className="see_more" onClick={() => navigate("/message")}>
          Xem tất cả
          <Icon icon="arrow-right" size={12} />
        </span>
      </div>
      <div className="list">
        {messages.length > 0 ? (
          messages
            .slice(0, 10)
            .map((message, index) => (
              <MessageItem key={index} message={message} isActive={false} />
            ))
        ) : (
          <DataNonePage />
        )}
      </div>
    </div>
  );
};

export default MessageNotification;
