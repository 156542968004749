import logo from "assets/logo.svg";
import Icon from "components/Icon";
import Loading from "components/Loading";
import MessageNotification from "components/Notification/MessageNotification";
import NotifyNotification from "components/Notification/NotifyNotification";
import { listUserPopup } from "data/SideBar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import OnboardingAtom from "services/onboarding/onboarding.atom";
import { StorageConstant } from "services/storage";
import { UserApi, UserAtom } from "services/user";
import useClickOutSide from "hooks/useClickOutSide";
import "./Header.less";
import { ChatApi, ChatAtom } from "services/chat";
import { io } from "socket.io-client";
import useQuery from "utils/useQuery";
import Avatar from "components/Avatar";
import { v4 as uuid } from "uuid";
import { NotificationApi, NotificationAtom } from "services/notification";
import { SocketChannelEnum, SocketProps } from "types";
import { ProductAtom } from "services/product";
import { ServiceAtom } from "services/service";
import { useSnackbar } from "notistack";
import { SidebarAtom } from "services/sidebar";
import ModalNews from "components/ModalNews";
import NewsAtom from "services/news/news.atom";

const socket = io(process.env.REACT_APP_CARX_DOMAIN || "", {
  transports: ["websocket"],
  extraHeaders: {
    token: `Bearer ${localStorage.getItem(StorageConstant.ACCESS_TOKEN)}`,
  },
});

const Header: React.FC = () => {
  const [user, setUser] = useRecoilState(UserAtom.userInfo);
  const setCompany = useSetRecoilState(OnboardingAtom.businessInfo);
  const [agent, setAgent] = useRecoilState(OnboardingAtom.agentInfo);
  const [loading, setLoading] = useState(false);
  const [conversationList] = useRecoilState(ChatAtom.convertsationList);
  const conversationDetails = useRecoilValue(ChatAtom.conversationDetails);
  const [messageList, setMessageList] = useRecoilState(ChatAtom.messageList);
  const notificationList = useRecoilValue(NotificationAtom.notificationList);
  const notificationSystemList = useRecoilValue(
    NotificationAtom.notificationSystemList
  );
  const [productFile, setProductFile] = useRecoilState(ProductAtom.fileUpload);
  const [serviceFile, setServiceFile] = useRecoilState(ServiceAtom.fileUpload);
  const query = useQuery();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSidebar, setIsSidebar] = useRecoilState(SidebarAtom.isSidebar);
  const [newsDetail, setNewsDetail] = useRecoilState(NewsAtom.newsDetail);

  const {
    nodeRef: notificationRef,
    show: showNotification,
    setShow: setShowNotification,
  } = useClickOutSide();
  const {
    nodeRef: messageRef,
    show: showMessage,
    setShow: setShowMessage,
  } = useClickOutSide();
  const {
    nodeRef: dropdownRef,
    show: showDropdown,
    setShow: setShowDropdown,
  } = useClickOutSide();

  const handleLogout = () => {
    setUser({});
    setAgent({});
    setCompany({});
    localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
    navigate("/sign-in");
  };

  useEffect(() => {
    setLoading(true);
    user.id &&
      UserApi.getDetailUser(user.id).then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          setUser(res.data);
          setCompany(res.data.company);
          setAgent(res.data.agent);
          return;
        }
        localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
        setUser({});
        setCompany({});
        setAgent({});
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    agent.id &&
      ChatApi.getConversationList(
        conversationList.page,
        conversationList.itemPerPage,
        agent.id
      );
  }, [
    agent.id,
    conversationList.page,
    conversationList.itemPerPage,
    messageList.new_message,
  ]);

  useEffect(() => {
    user.id &&
      NotificationApi.getNotificationList(
        notificationList.page,
        notificationList.itemPerPage,
        user.id
      );
  }, [user.id, notificationList.page, notificationList.itemPerPage]);

  useEffect(() => {
    NotificationApi.getNotificationSystemList(
      notificationSystemList.page,
      notificationSystemList.itemPerPage
    );
  }, [notificationSystemList.page, notificationSystemList.itemPerPage]);

  useEffect(() => {
    user.id && NotificationApi.getCountNotification(user.id);
  }, [user.id]);

  useEffect(() => {
    socket.on(`ROOM_${user.id}`, (res: SocketProps) => {
      switch (res.channel) {
        case SocketChannelEnum.CARX_PRODUCT: {
          if (productFile.loading) {
            enqueueSnackbar(res.data.message, {
              variant: res.data.success ? "success" : "error",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
          setProductFile({ loading: false, data: res.data });
          break;
        }
        case SocketChannelEnum.CARX_SERVICE: {
          if (serviceFile.loading) {
            enqueueSnackbar(res.data.message, {
              variant: res.data.success ? "success" : "error",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
          setServiceFile({ loading: false, data: res.data });
          break;
        }
        case SocketChannelEnum.CARX_NOTIFICATION: {
          if (user.id) {
            NotificationApi.getNotificationList(
              1,
              notificationList.itemPerPage,
              user.id
            );
            NotificationApi.getCountNotification(user.id);
          }

          break;
        }
      }
    });

    return () => {
      socket.off(`ROOM_${user.id}`);
    };
    // eslint-disable-next-line
  }, [user.id, productFile, serviceFile]);

  useEffect(() => {
    agent &&
      socket.on(`ROOM_${agent.id}`, (res) => {
        switch (res.channel) {
          case SocketChannelEnum.CARX_MESSAGES: {
            const _data = {
              conversation_id: res.data.conversation_id,
              created_at: res.data.timestamp,
              message: res.data.message,
              receiver_id: agent.id,
              sender_id: res.data.sender_id,
            };
            if (
              query.id === _data.conversation_id &&
              conversationDetails?.customer.id === _data.sender_id
            ) {
              setMessageList({
                ...messageList,
                data: [...messageList.data, _data],
                new_message: uuid(),
              });
            }
            break;
          }
        }
      });

    return () => {
      socket.off(`ROOM_${agent.id}`);
    };

    // eslint-disable-next-line
  }, [messageList]);

  useEffect(() => {
    socket.on("ROOM_CARX", (res) => {
      switch (res.channel) {
        case SocketChannelEnum.CARX_NOTIFICATION: {
          if (user.id) {
            NotificationApi.getNotificationSystemList(
              1,
              notificationList.itemPerPage
            );
            NotificationApi.getCountNotification(user.id);
          }
        }
      }
    });

    return () => {
      socket.off("ROOM_CARX");
    };
    // eslint-disable-next-line
  }, [user.id]);

  return (
    <>
      <header className="header">
        <div className="header_left">
          <Icon
            icon="menu"
            size={20}
            className="icon"
            onClick={() => setIsSidebar(!isSidebar)}
          />
          <img src={logo} alt="" onClick={() => navigate("/")} />
        </div>
        <div className="header_right">
          {/* message notification */}
          <div
            ref={messageRef}
            className="icon"
            onClick={() => setShowMessage(!showMessage)}
          >
            <Icon icon="chat" size={20} />
            {/* <span>6</span> */}
          </div>
          {showMessage && (
            <div className="notificationShow">
              <MessageNotification
                sticky={true}
                messages={conversationList.data}
              />
            </div>
          )}

          {/* notify notification */}
          <div
            ref={notificationRef}
            className="icon"
            onClick={() => setShowNotification(!showNotification)}
          >
            <Icon icon="notification" size={22} />
            {notificationList.count > 0 && (
              <span>
                {notificationList.count >= 100 ? "99+" : notificationList.count}
              </span>
            )}
          </div>

          {showNotification && (
            <div className="notificationShow">
              <NotifyNotification sticky={true} />
            </div>
          )}
          {/* profile dropdown */}
          <div
            ref={dropdownRef}
            className="avatar"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <Avatar src={agent?.avatar} alt={agent?.name} />
            <span>{agent?.name || "-"}</span>
          </div>
          {showDropdown && (
            <>
              <div className="overlay"></div>
              <div className="children">
                {listUserPopup.map((children, index) => (
                  <span key={index} onClick={() => navigate(children.link)}>
                    {children.text}
                    <Icon icon="arrow-right" size={8} />
                  </span>
                ))}
                <p onClick={() => handleLogout()}>Đăng xuất</p>
              </div>
            </>
          )}
        </div>
      </header>

      <ModalNews
        visible={!!newsDetail}
        onClose={() => setNewsDetail(undefined)}
      />
      {loading && <Loading />}
    </>
  );
};

export default Header;
