import { atom } from "recoil";
import { IRescue } from "./type";

interface RescueOnsiteList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IRescue[];
  loading: boolean;
  name?: string;
}

interface RescueTowingList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IRescue[];
  loading: boolean;
  name?: string;
}

interface RescueRepairList {
  page: number;
  itemPerPage: number;
  total: number;
  data: IRescue[];
  loading: boolean;
  name?: string;
}

interface OnsiteDetails {
  loading: boolean;
  details?: IRescue;
}

interface TowingDetails {
  loading: boolean;
  details?: IRescue;
}

interface RepairDetails {
  loading: boolean;
  details?: IRescue;
}

const onsiteList = atom<RescueOnsiteList>({
  key: "onsite_rescue_list",
  default: {
    page: 1,
    itemPerPage: 12,
    total: 0,
    data: [],
    loading: false,
  },
});

const onsiteDetails = atom<OnsiteDetails>({
  key: "onsite_rescue_details",
  default: {
    loading: false,
  },
});

const towingList = atom<RescueTowingList>({
  key: "towing_rescue_list",
  default: {
    page: 1,
    itemPerPage: 12,
    total: 0,
    data: [],
    loading: false,
  },
});

const towingDetails = atom<TowingDetails>({
  key: "towing_rescue_details",
  default: {
    loading: false,
  },
});

const repairList = atom<RescueRepairList>({
  key: "repair_rescue_list",
  default: {
    page: 1,
    itemPerPage: 12,
    total: 0,
    data: [],
    loading: false,
  },
});

const repairDetails = atom<RepairDetails>({
  key: "repair_rescue_details",
  default: {
    loading: false,
  },
});

const RescueAtom = {
  onsiteList,
  onsiteDetails,
  towingList,
  towingDetails,
  repairList,
  repairDetails,
};

export default RescueAtom;
